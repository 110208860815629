.coef {
  position: absolute;
  left: 0;
  top: -80px;
  width: 100%;
  text-align: center;
  padding-left: 20px;
  box-sizing: border-box;
  color: #fff;
  font-size: 55px;
  font-family: "Roboto-Black";
  transform: translateY(0) rotate(1deg);
  animation: coefAnimation 0.68s 40ms linear infinite;
  z-index: 2;
}

@media (min-width: 320px) and (max-width: 480px) {
  .coef {
    top: -50px;
    padding-left: 15px;
    font-size: 33px;
  }
}

@keyframes coefAnimation {
  20% {
    transform: translateY(17px) rotate(2deg);
  }
  25% {
      transform: translateY(17px) rotate(2deg);
  }
  47% {
      transform: translateY(-5px) rotate(1deg);
  }
  74% {
      transform: translateY(17px) rotate(2deg);
  }
  100% {
      transform: translateY(0) rotate(1deg);
  }
}