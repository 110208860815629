.dino {
  position: absolute;
  left: 50%;
  margin-left: -220px;
  bottom: 10px;
  width: 360px;
  height: 240px;
  user-select: none;
  z-index: 6;

  &__wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__animation {
    width: calc(360px * 17);
    height: 100%;
    background: url('../img/animation.png');
    background-size: 100% 100%;
    animation: dinoAnimation 680ms steps(17);
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
  }

  &__crash-wrapper {
    position: absolute;
    left: calc(50% - 1000px / 2);
    bottom: 0;
    width: 1000px;
    height: 563px;
    overflow: hidden;
    z-index: 6;
  }

  &__crash {
    width: calc(1000px * 58);
    height: 563px;
    background: url('../img/crash.png') no-repeat;
    background-size: 100% 100%;
    animation: crashAnimation 1.47s steps(49) forwards, crashAnimationLoop 270ms steps(9) 1470ms infinite;
  }

  &__result {
    z-index: 7;
    position: absolute;
    left: 0;
    bottom: 170px;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Roboto-Black";
    opacity: 0;
    text-shadow: 6px 6px 1px rgba(0, 0, 0, .6);
    animation: resultAnimation 150ms 500ms linear forwards;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .dino {
    width: 180px;
    height: 120px;
    margin-left: -110px;
    &__animation {
      width: calc(180px * 17);
    }
    &__crash-wrapper {
      left: calc(50% - 400px / 2);
      width: 400px;
      height: 225px;
    }
    &__crash {
      width: calc(400px * 58);
      height: 225px;
      animation: crashAnimationMobile 1.47s steps(49) forwards, crashAnimationLoopMobile 270ms steps(9) 1470ms infinite;
    }
    &__result {
      bottom: 50px;
      animation: resultAnimationMobile 150ms 500ms linear forwards;
    }
  }
}

@keyframes dinoAnimation {
  100% {
    transform: translateX(-100%);
  }
}

@keyframes crashAnimation {
  100% {
    transform: translateX(calc(1000px * 49 * -1));
  }
}

@keyframes crashAnimationMobile {
  100% {
    transform: translateX(calc(400px * 49 * -1));
  }
}

@keyframes crashAnimationLoop {
  0% {
    transform: translateX(calc(1000px * 49 * -1));
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes crashAnimationLoopMobile {
  0% {
    transform: translateX(calc(400px * 49 * -1));
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes resultAnimation {
  0% {
    opacity: 1;
    font-size: 30px;
  }
  80% {
      opacity: 1;
      font-size: calc(30px * 3.3);
  }
  100% {
      opacity: 1;
      font-size: calc(30px * 2.5);
  }
}

@keyframes resultAnimationMobile {
  0% {
    opacity: 1;
    font-size: 16px;
  }
  80% {
      opacity: 1;
      font-size: calc(16px * 3.3);
  }
  100% {
      opacity: 1;
      font-size: calc(16px * 2.5);
  }
}
