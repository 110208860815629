.game-window {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  &_isPauseAnimation {
    .game-window {
      &__bg1 {
        animation-play-state: paused !important;
      }
      &__bg2 {
        animation-play-state: paused !important;
      }
      &__bg3 {
        animation-play-state: paused !important;
      }
      &__bg4 {
        animation-play-state: paused !important;
      }
    }
  }

  &__bg1 {
    position: absolute;
    left: 0;
    top: 0;
    width: 200%;
    height: 100%;
    background: url('./img/bg1.jpeg') repeat;
    background-size: 50% 100%;
    animation: bgAnimation 15s linear infinite;
    z-index: 1;
  }

  &__bg2 {
    position: absolute;
    left: 0;
    width: 200%;
    height: 100%;
    background: url('./img/bg2.png') repeat;
    background-size: 25% 100%;
    bottom: 57px;
    width: 2264px;
    height: 120px;
    opacity: .7;
    animation: bg2Animation 3s linear infinite;
    z-index: 2;
  }

  &__bg3 {
    position: absolute;
    left: 0;
    bottom: 55px;
    width: 3284px;
    filter: blur(1px);
    display: flex;
    align-items: center;
    animation: bg3Animation 3.8s linear infinite;
    z-index: 4;
  }

  &__stone {
    margin-left: 1642px;
    width: 71px;
    min-width: 71px;
    height: 100px;
    background: url('./img/bg3.png');
    background-size: 100% 100%;
  }

  &__bg4 {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 2463px;
    height: 65px;
    background: url('./img/bg4.png') repeat;
    background-size: 821px 100%;
    animation: bg4Animation 1s linear infinite;
    z-index: 5;
  }

  &__bg5 {
    position: absolute;
    left: 0;
    width: 134px;
    height: 97px;
    background: url('./img/bg5.png');
    animation: bg5Animation 1s linear;
    animation-fill-mode: forwards;
    background-size: 100% 100%;
    transform: translate(-100%, -100%);
    z-index: 3;
  }

  &__bg6 {
    position: absolute;
    top: 0;
    width: 719px;
    height: 514px;
    background: url('./img/bg6.png');
    animation: bg6Animation 1.2s linear;
    animation-fill-mode: forwards;
    background-size: 100% 100%;
    transform: translate(-100%, -100%);
    z-index: 3;
  }

  &_isPauseAnimation {
    animation-play-state: paused !important;
  }
  &__end-image {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    height: 10rem;
  }
}

@media (max-width: 575.98px) {
  .game-window {
    &__bg2 {
      bottom: 28px;
      width: 1132px;
      height: 60px;
      animation: bg2Animation 6s linear infinite;
    }
    &__bg3 {
      bottom: 27px;
      width: 1616px;
      animation: bg3Animation 7.6s linear infinite;
    }
    &__bg4 {
      width: 1212px;
      height: 32px;
      background-size: 411px 100%;
      animation: bg4Animation 2s linear infinite;
    }
    &__stone {
      width: 36px;
      min-width: 36px;
      height: 50px;
    }
  }
}

@keyframes bgAnimation {
  0% {
   transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes bg2Animation {
  0% {
   transform: translateX(0);
  }
  100% {
    transform: translateX(-566px);
  }
}

@keyframes bg3Animation {
  0% {
   transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes bg4Animation {
  0% {
   transform: translateX(0);
  }
  100% {
    transform: translateX(-821px);
  }
}

@keyframes bg5Animation {
  100% {
    opacity: 0;
    transform: translate(300%, 300%);
  }
}

@keyframes bg6Animation {
  100% {
    opacity: 0;
    transform: translate(20%, 20%);
  }
}

@keyframes backgroundAnimation {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 200%;
  }
}

@keyframes backgroundVerticalAnimation {
  0% {
    background-position-y: 100%;
  }
  100% {
    background-position-y: 0%;
  }
}