.full-my-bets {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 8000;
  box-sizing: border-box;
  background-color: rgba(20, 25, 38, .82);
  animation: rulesAnimation .2s linear forwards;

  &__bg {
    width: 100%;
    min-height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__wrapper {
    cursor: default;
    padding: 15px;
    background-color: #1f2533;
    border-radius: 5px;
    position: relative;
    color: #97a1c2;
    width: 70%;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    font-size: 14px;
    color: #ccd6f6;
  }

  &__close {
    cursor: pointer;
  }

  &__filters {
    display: flex;
    justify-content: flex-end;
    margin: 0 5%;
    height: 40px;
  }

  &__body {
    margin: 15px 5%;
    height: calc(100vh - 300px);
  }

  &__table-header {
    background-color: #151b29;
    border-radius: 5px;
    width: 100%;
    color: #97a1c2;
    font-size: 13px;
    border-spacing: 10px 0;

    th {
      text-align: left;
      height: 40px;
      color: #e2e8fb;
      width: 15%;
      padding: 0;

      &:nth-child(1) {
        width: 25%;
      }
    }
  }

  &__date-pickers-container {
    display: flex;
  }

  &__date-picker-container {
    margin-right: 5px;
  }

  &__filters-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #283042;
    border-radius: 5px;
    width: 68px;
    cursor: pointer;
  }
  
  &_isWin {
    color: #a1f15b;
  }

  &__table-body {
    overflow-y: auto;
    height: calc(100% - 40px);
    table {
      width: 100%;
      color: #97a1c2;
      font-size: 13px;
      border-spacing: 10px 0;
      tr {
        padding: 0 15px;
        td {
          height: 26px;
          width: 15%;
          padding: 0;
  
          &:nth-child(1) {
            width: 25%;
          }

          b {
            color: #97a1c2;
          }
        }
      }
    }
  }
  &__amount {
    color: #ccd6f6;
    &_isWin {
      color: #bef159;
    }
  }
}

@media (max-width: 767.98px) {
  .full-my-bets {
     width: 100vw;
     height: 100vh;
     position: fixed;
     overflow: auto;
     &__wrapper {
      width: 100%;
      height: 100vh;
      padding: 10px;
     }
     &__content {
       box-sizing: border-box;
     }
     &__body {
      margin: 15px 0;
      height: calc(100vh - 120px);
     }
     &__filters {
       height: 32px;
       justify-content: space-between;
       margin: 0 0;
     }


     &__date-picker-container {
      margin-right: 5px;
    }
     
     &__table-header {
      font-size: 10px;
      border-spacing: 5px 0;
     }
     &__table-body {
      table {
        border-spacing: 5px 0;
        font-size: 10px;
        tr {
          td {
            width: 17%;
            height: 32px;
    
            &:nth-child(1) {
              width: 18%;
            }
            &:nth-child(5) {
              width: 14%;
            }
          }
        }
      }
    }
    th {
      width: 17%;

      &:nth-child(1) {
        width: 18%;
      }
      &:nth-child(5) {
        width: 14%;
      }
    }
  }
}