
    .date-picker {
      height: 32px;
      background-color: #283042;
      border-radius: 5px;
      font-size: 12px;
      padding: 5px;
      display: flex;
      align-items: center;
      position: relative;
      box-sizing: border-box;
  
    &__input {
      background-color: initial;
      color: #ccd6f6;
      text-align: center;
      border: 0;
      outline: 0;
      font-size: inherit;
      font-family: "Proxima Nova Bold";
      user-select: none;
      width: 100px;
    }
  
    &__input::-webkit-calendar-picker-indicator {
      opacity: 0;
      width: 5px;
      height: 20px;
      cursor: pointer;
    }
  
    &__img {
      position: absolute;
      right: 10px;
      pointer-events: none;
    }

}

@media screen and (min-width: 700px) {
    .date-picker {
            height: 40px;
            padding: 12px 20px;

          &__input {
            width: auto;
          }

          &__input::-webkit-calendar-picker-indicator {
            height: 24px;
            width: 24px;
          }
        
          &__img {
            height: 24px;
            width: 24px;
            right: 20px;
          }

    }
}

