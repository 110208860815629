.pause {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 999;
  align-items: center;
  flex-direction: column;
  background: rgba(0, 0, 0, .4);
  display: flex;

  &__egg {
    vertical-align: middle;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    user-select: none;
    margin-top: 160px;
    animation: eggAnimation 1.1s linear infinite;
  }

  &__loader {
    width: 180px;
    height: 10px;
    margin-top: 30px;
    background-color: #1e2334;
    overflow: hidden;
  }

  &__loader-value {
    width: 0;
    height: 100%;
    background-color: #00ffac;
    animation: loaderAnimation forwards linear;
  }

  &__percent {
    color: #00ffac;
    margin-top: 12px;
    font-size: 18px;
  }

  &__text {
    margin-top: 1.5rem;
    font-size: 1.5rem;
  }
}

@media (max-width: 575.98px) {
 .pause {
   &__egg {
    margin-top: 4vw;
    width: 20vw;
   }
   &__loader {
    width: 50vw;
    margin-top: 4vw;
   }
   &__text {
    margin-top: 4vw;
    font-size: 4vw;
   }
 }
}

@keyframes eggAnimation {
  5% {
    transform: translateY(-10px) rotate(10deg);
  }
  10% {
      transform: rotate(8deg);
  }
  15% {
      transform: rotate(3deg);
  }
  20% {
      transform: rotate(8deg);
  }
  25% {
      transform: rotate(6deg);
  }
  30% {
      transform: rotate(4deg);
  }
  35% {
      transform: rotate(2deg);
  }
  44% {
      transform: rotate(2deg);
  }
  45% {
      transform: translateY(-6px) rotate(10deg);
  }
  50% {
      transform: translateY(0) rotate(7deg);
  }
  55% {
      transform: translateY(0) rotate(3deg);
  }
  60% {
      transform: none;
  }
  70% {
      transform: translateY(0) rotate(5deg);
  }
  75% {
      transform: translateY(0) rotate(2deg);
  }
  80% {
      transform: none;
  }
  100% {
      transform: none;
  }
}

@keyframes loaderAnimation {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
