.basket {
  flex-basis: 50%;
  height: 100%;
  font-weight: normal;
  display: flex;
  user-select: none;
  box-sizing: border-box;
  flex-direction: column-reverse;
  margin-bottom: 20px;
  @media screen and (max-width: 1440px) {
    justify-content: center;
    flex-basis: 50%;
  }
  &:last-child {
    padding-top: 10px;
  }
  &__content {
    &_isBlock {
      opacity: .4;
    }
  }
  &__button-layout {
    width: 100%;
    height: 75px;
    max-width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
    z-index: 0;
    button {
      border-radius: 5px;
      font-size: 22px;
    }
  }
  &__content-layput {
    display: flex;
    height: 100%;
  }
  &__rows {
    position: relative;
    &_isBlock {
      opacity: .4;
    }
  }
  &__content-amounts {
    width: 60px;
    height: 100%;
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  &__amount-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #646a80;
    color: #656c82;
    font-size: 12px;
    width: 100%;
    height: 28px;
    transition: filter 100ms ease-in;
    cursor: pointer;
    &:hover {
      background-color: #141b2b;
      color: #97a1c2;
      filter: brightness(1.1);
    }
  }
  &__row {
    border: 1px solid rgba(100, 106, 128, 0.7);
    border-radius: 5px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 0 10px;

    &:last-child {
      margin-top: 5px;
    }
  }
  &__switchbox-wrapper {
    height: 40px;
    display: flex;
    align-items: center;
    margin-right: 15px;
  }
  &__change-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 52px;
    position: relative;
  }
  &__title {
    color: #646a80;
    font-size: 12px;
    width: 60px;
    position: absolute;
    top: 8px;
  }
  &__change-elements {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  &__change-button {
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #363e53;
    cursor: pointer;
    user-select: none;
    &:last-child {
      margin-left: 5px;
    }
  }
  &_isBlock {
    .basket__left {
      * {
        cursor: not-allowed !important;
      }
      .button {
        cursor: pointer !important;
      }
    }
  }
  &__input {
    width: calc(100% - 40px * 2 - 5px);
    position: relative;
    &:not(:focus-within) {
      .input {
        opacity: 0;
      }
      .basket {
        &__overlay {
          visibility: visible;
        }
      }
    }
    .input {
      text-overflow: ellipsis;
      vertical-align: middle;
      appearance: none;
      flex-basis: auto;
      color: #a1f15b;
      font-size: 16px;
      background: transparent;
      border-radius: 5px;
      width: 100%;
      height: 36px;
      margin-top: 16px;
      padding: initial;
      min-height: initial;
    }
  }
  &__overlay {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    font-size: 16px;
    background: transparent;
    border-radius: 5px;
    width: 100%;
    height: 36px;
    top: 16px;
  }
  &__input-val {
    color: #a1f15b;
  }
  &__input-val-ending {
    margin-left: 10px;
    color: #97a1c2;
    &_color {
      &_green {
        margin-left: 0;
        color: #a1f15b;
      }
    }
  }
  &__cashout-text {
    color: #ffffff;
  }
  &__cashout-amount {
    color: #ffe338;
    font-size: 22px;
  }
  &__cashout-currency {
    color: #f1b200;
  }
  &__toggle {
    transform: translateY(-12px);
    z-index: 1;
    display: flex;
    justify-content: center;
  }
  &__toggle-btn {
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: rgba(12,18,30,.4);
    border-radius: 5px;
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      left: 3px;
      top: 3px;
      width: 24px;
      height: 24px;
      background-color: #363e53;
      border-radius: 5px;
      background-image: url('./img/arrow-down.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    &_isOpenFull {
      &::before {
        background-image: url('./img/arrow-up.png');
      }
    }
  }
}

.basket-desktop {
  flex-basis: 50%;
  height: 100%;
  font-weight: normal;
  display: flex;
  user-select: none;
  overflow: hidden;
  box-sizing: border-box;
  @media (max-width: 991.98px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 1440px) {
    justify-content: center;
    flex-basis: 50%;
  }
  &:last-child {
    @media screen and (max-width: 1440px) {
      margin-top: 10px;
    }
  }
  &__content {
    &_isBlock {
      opacity: .4;
    }
  }
  &__button-layout {
    width: 100%;
    height: 100%;
    max-width: 270px;
    padding: 0 5px;
    button {
      border-radius: 5px;
      white-space: normal;
    }
    @media screen and (max-width: 1440px) {
      padding-left: 10px;
      padding-right: 0;
    }
    @media (max-width: 991.98px) {
      height: 75px;
      max-width: 382px;
      padding-left: unset;
      margin-top: 5px;
    }
  }
  &__content-layput {
    display: flex;
    height: 100%;
  }
  &__rows {
    position: relative;
    &_isBlock {
      opacity: .4;
    }
  }
  &__content-amounts {
    width: 60px;
    height: 100%;
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  &__amount-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #646a80;
    color: #656c82;
    font-size: 12px;
    width: 100%;
    height: 28px;
    transition: filter 100ms ease-in;
    cursor: pointer;
    &:hover {
      background-color: #141b2b;
      color: #97a1c2;
      filter: brightness(1.1);
    }
  }
  &__row {
    border: 1px solid rgba(100, 106, 128, 0.7);
    border-radius: 5px;
    display: flex;
    align-items: center;
    width: 315px;
    height: 60px;
    padding: 0 10px;

    &:last-child {
      margin-top: 5px;
    }
  }
  &__switchbox-wrapper {
    height: 40px;
    display: flex;
    align-items: center;
    margin-right: 15px;
  }
  &__change-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 52px;
    position: relative;
  }
  &__title {
    color: #646a80;
    font-size: 12px;
    width: 60px;
    position: absolute;
    top: 8px;
  }
  &__change-elements {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  &__change-button {
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #363e53;
    cursor: pointer;
    user-select: none;
    &:last-child {
      margin-left: 5px;
    }
  }
  &_isBlock {
    .basket-desktop__left {
      * {
        cursor: not-allowed !important;
      }
      .button {
        cursor: pointer !important;
      }
    }
  }
  &__input {
    width: calc(100% - 40px * 2 - 5px);
    position: relative;
    &:not(:focus-within) {
      .input {
        opacity: 0;
      }
      .basket-desktop {
        &__overlay {
          visibility: visible;
        }
      }
    }
    .input {
      text-overflow: ellipsis;
      vertical-align: middle;
      appearance: none;
      flex-basis: auto;
      color: #a1f15b;
      font-size: 16px;
      background: transparent;
      border-radius: 5px;
      width: 100%;
      height: 36px;
      margin-top: 16px;
      padding: initial;
      min-height: initial;
    }
  }
  &__overlay {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    font-size: 16px;
    background: transparent;
    border-radius: 5px;
    width: 100%;
    height: 36px;
    top: 16px;
  }
  &__input-val {
    color: #a1f15b;
  }
  &__input-val-ending {
    margin-left: 10px;
    color: #97a1c2;
    &_color {
      &_green {
        margin-left: 0;
        color: #a1f15b;
      }
    }
  }
  &__cashout-text {
    color: #ffffff;
  }
  &__cashout-amount {
    color: #ffe338;
    font-size: 22px;
  }
  &__cashout-currency {
    color: #f1b200;
  }
  &__container {
    display: flex;
    flex-direction: row;
  }
}