.history {
  position: inherit;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  background: #1f2533;
  padding: 20px;
  &__buttons-container {
    height: 40px;
    user-select: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__button-container {
    width: 30%;
    height: 100%;
    width: calc(100% / 3);
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
  &__content-container {
    height: calc(100% - 50px);
    margin-top: 10px;
  }
  &__section-title {
    position: relative;
    align-items: center;
  }
  &__bets-amount {
    padding-left: 10px;
    position: relative;
    color: #a1f15b;
  }
}

@media (max-width: 575.98px) {
  .history {
    padding: 10px;
  }
}

@keyframes increaceHistory {
  0% {
    max-height: 0;
    padding: 0 1rem;
  }
  100% {
    padding: 1rem;
    max-height: calc(100% - 4.2rem);
  }
}

@keyframes reduceHistory {
  0% {
    padding: 1rem;
    max-height: calc(100% - 4.2rem);
  }
  100% {
    max-height: 0;
    padding: 0 1rem;
  }
}